@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@700&display=swap');

/*font-face {*/
/*    font-family: "Museo Sans 700";*/
/*    src: url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.eot");*/
/*    src: url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.woff") format("woff"), url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.svg#Museo Sans 700") format("svg");*/
/*}*/

html {
    font-family: 'Nunito', 'Almarai' !important;
    scroll-behavior: smooth !important;
}

body {
    font-family: 'Nunito', 'Almarai' !important;
    background-color: #f9f9f9 !important;
}