@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Almarai:wght@700&display=swap);
/*font-face {*/
/*    font-family: "Museo Sans 700";*/
/*    src: url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.eot");*/
/*    src: url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.woff") format("woff"), url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/b1de76f2d448dcdf44d092114d6ba04a.svg#Museo Sans 700") format("svg");*/
/*}*/

html {
    font-family: 'Nunito', 'Almarai' !important;
    scroll-behavior: smooth !important;
}

body {
    font-family: 'Nunito', 'Almarai' !important;
    background-color: #f9f9f9 !important;
}
/* @import url(//db.onlinewebfonts.com/c/8b2760b7bcaf4e2cdbb6cacec092b91b?family=MuseoSans);

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {font-family: "MuseoSans"; src: url("//db.onlinewebfonts.com/t/8b2760b7bcaf4e2cdbb6cacec092b91b.eot"); src: url("//db.onlinewebfonts.com/t/8b2760b7bcaf4e2cdbb6cacec092b91b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8b2760b7bcaf4e2cdbb6cacec092b91b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8b2760b7bcaf4e2cdbb6cacec092b91b.woff") format("woff"), url("//db.onlinewebfonts.com/t/8b2760b7bcaf4e2cdbb6cacec092b91b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8b2760b7bcaf4e2cdbb6cacec092b91b.svg#MuseoSans-900") format("svg"); }
  */
/* @import url(//db.onlinewebfonts.com/c/8b2760b7bcaf4e2cdbb6cacec092b91b?family=MuseoSans-900); */
.styleUL{
  background-color: bisque;
  border-radius: 30px;
  padding: 1px !important;
}
a {
  text-decoration: none !important;
}

button:focus {
  outline: none !important;
  /* outline: -webkit-focus-ring-color auto 5px; */
}

a:hover {
  text-decoration: none !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #eb8919 !important;
}

.current {
  color: #eb8919 !important;
  border-bottom: 2px solid #eb8919;
}

.sec2{
  background-image: url("/static/media/Path 2161.92c569c7.png");
  background-size: cover;
  background-position: center;
  min-height: 400px;
}

.hr{
  margin: auto;
  max-width: 90% !important;
  color: #eb8919 !important;
  height: 5px !important;
  opacity: 1 !important;
}
.hr1{
  max-width: 30% !important;
  color: #eb8919 !important;
  height: 5px !important;
  opacity: 1 !important;
}
.img-navbar {
  object-fit: contain;
  width: 80px;
  height: 50px;
}
#interface{
  background-color: blanchedalmond;
}
@media only screen and (min-width: 1024px) {

  .lastsech2 {
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
    margin-left: 120px;
  }

  .lastsech4 {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.213rem;
    font-weight: 600;
    max-width: 70%;
    margin-left: 120px;
  }
}

@media only screen and (max-width: 1023px) {

  .lastsech2 {
    color: #ffffff;
    font-size: 2rem;
    font-weight: bold;
  }

  .lastsech4 {
    color: rgb(255, 255, 255);
    font-size: 1.013rem;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1024px) {
  .first {
    margin-top: 50px !important;
    background-image: url(/static/media/background.68e9fe42.png);
    background-size: cover;
    background-position: center;
    min-height: 800px;
  }
  .head3 {
    max-width: 70%;
    font-size: 18px;
    font-weight: 600;
    color: #908e8e;
  }
  .head4 {
    font-size: 18px;
    font-weight: 600;
    color: #908e8e;
  }
  .head1 {
    font-size: 3rem;
    font-weight: bold;
  }
  .divone {
    margin-left: 5%;
  }
  .divvv{
    margin-top: 15%;
    margin-left: 20%;
    text-align: left;
  }
  .divSec1{
    display: none !important;
  }
  .homesec {
    padding-top: 70px !important;
    padding-bottom: 50px !important;
  }
  #last {
    background-color: #2b2e33;
    width: 100%;
    height: 20.875rem;
    margin: 0 !important;
    border-top-right-radius: 40%;

  }
}

@media only screen and (max-width: 1023px) {
  .first {
    margin-top: 30px !important;
  }

  .head3 {
    width: 95%;
    font-size: 16px;
    font-weight: 600;
    color: #908e8e;
  }
  .head1 {
    font-size: 2.2rem;
    font-weight: bold;
  }
  .divvv{
    margin-top: 15%;
    margin-left:2%;
    text-align: left;
  }
  .divSec1{
    display: block !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }
  .homesec {
    padding-top: 50px !important;
  }
  #last {
    background-color: #2b2e33;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 !important;
    border-top-right-radius: 25%;
  }
}


@media only screen and (min-width: 1400px) {
  .customclass {
 margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .bg {
    display: inline;
  }
}

@media only screen and (min-width: 900px) {
  .filtermobile {
    width: 70%;
    display: block;
    margin: auto;
  }
}

.navbar {
  height: 74px;
  padding: 0 !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background-color: #ffffff !important;
}

.navbar-header {
  background-color: #ffffff;
  height: 74px;
}



.nav-link1 {
  height: 100% !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: center !important;

}

@media (min-width: 992px) {
  .nav-item {
    /* height: 74px !important; */
    /* margin: 0 24px 1px 0 !important; */
    /* padding: 29px 30px 24px !important; */
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 991px) {
  .nav-item {
    /* height: 74px !important; */
    /* margin: 0 24px 1px 0 !important; */
    /* padding: 29px 30px 24px !important; */
    margin-bottom: 15px;
  }

  .navbar-nav {
    justify-content: center !important;
    align-items: center !important;
  }

  .explore {
    height: 48px;
    margin: 0px !important;
    font-size: 26px !important;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #33333a;
  }
}

@media (min-width: 1260px) {
  .navcont {
    padding-inline: 80px !important;
    background-color: #fff;
  }
}

.active-link {
  color: #33333a !important;
}

.bg {
  height: 42px;
  margin: 5px 10px 5px 10px;
  text-align: center;
  padding: 10px 09px;
  border-radius: 12px;
  background-color: #efeff0;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.Labletech {
  height: 21px;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #908e8e;
  border-style: none;
}

.bg-btn1 {
  /* height: 40px; */
  margin: 5px 10px 5px 10px;
  text-align: center;
  padding: 10px 09px;
  border-radius: 12px;
  background-color: #f2871e;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.Label-btn1 {
  height: 21px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-style: none;
  background-color: transparent;
  background-color: initial;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0rem !important;
}

.bg-btn1:focus {
  box-shadow: 0 0 0 0rem !important;
  border-style: none !important;
}

.Label-lang {
  height: 21px;
  margin: 5px 3px 0 6px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33333a;
}

.imgstar {
  margin: -9px 9px 8px 10px;
  object-fit: contain;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f2871e;
}

.numstar {
  height: 48px;
  margin: 0px 37px 78px 9px;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  color: #33333a;
}

.parstar {
  height: 21px;
  margin: 8px 5px 59px 2px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #908e8e;
}

.divstar {
  margin: 0px 0 0;
  padding: 13px 10px 12px 10px;
  border-radius: 12px;
  background-color: #efeff0;
  min-width: 100px;
}

.btnstar {
  /* min-width:  100px; */
  width: 100%;
  height: 21px;
  margin: 0 0 0 0px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  background-color: transparent;
  background-color: initial;
  border-style: none;
  text-align: center;
  color: #908e8e;
}

.iconafter {
  height: 18px;
  margin: 1px 6px 2px 0;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #908e8e;
}

.borderrow {
  border-right: solid 1px #ede8e9;
}

.cardborder {
  height: 222px;
  /* margin: 46px 151px 24px 28px; */
  padding: 10px 28px 26px;
  border-radius: 12px;
  border: solid 1px #ede8e9;
  background-color: #ffffff;
  min-width: 750px;
}

.explore {
  height: 48px;
  margin: 40px 50px 11px 0;
  font-size: 34px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  color: #33333a;
}

.explorespan {
  color: #f2871e;
}

.buttonexplore {
  /* height: 36px; */
  /* min-width: 100px; */
  margin: 11px 16px 5px 0 !important;
  padding: 3px 34px 8px 34px !important;
  border-radius: 10px !important;
  background-color: #efeff0 !important;
  border-color: #efeff0 !important;
}

.yes {
  border-radius: 11.4px;
}

.example {
  margin-bottom: 20px;
  margin-top: 20%;

  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
  min-height: 270px;
}

.ant-spin-dot-item {
  background-color: #f2871e !important;
}

.buttonexplore1 {
  height: 36px;
  /* min-width: 100px; */
  /* margin: 0px 0px 0px 33px !important; */
  /* padding: 3px 34px 8px 34px !important; */
  border-radius: 10px !important;
  background-color: #efeff0 !important;
  border-color: #efeff0 !important;
  margin: 1px !important;
}

.buttonexplore:hover {
  border-color: #efeff0 !important;
  color: #fff !important;
}

.buttonexplore1:hover {
  border-color: #efeff0 !important;
}

.spbtn {
  height: 18px;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #908e8e;
  border-color: #efeff0;
}

.spbtn1 {
  height: 18px;
  font-size: 0.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #908e8e;
  border-color: #efeff0;
}

.activecolorspan {
  background-color: #feedde !important;
  color: #f2871e !important;
}

.cardnew {
  /* height: 300px; */
  text-align: center;
  margin: 0 0 16px 0;
  padding: 10px;
  border-radius: 11.4px;
  border: solid 0.5px #ede8e9;
  background-color: #ffffff;
}

.avatar {
  width: 170px;
  height: 170px;
  margin: 0 0 8px;
  object-fit: cover;
  border-radius: 11.4px;
  max-width: 100%;
}

.text-avatar {
  /* height: 22px; */
  margin: 0 4px 4px 0;
  /* font-family: DMSans; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #12022f;
}

.Director-of-UserTest {
  /* width: 130px; */
  /* height: 16px; */
  margin: 4px 0 8px 4px;
  /* font-family: MuseoSans; */
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #908e8e;
}

.copy-bg {
  height: 44px !important;
  min-width: 100%;
  margin: 0 0 22px !important;
  padding: 10px 10px 10px !important;
  border-radius: 6px !important;
  background-color: #efeff0 !important;
}

.copy-bg:hover {
  border-color: #efeff0 !important;
  border-color: #efeff0 !important;
  color: #fff !important;
}

.copy-bg:focus {
  border-color: #efeff0 !important;
}

.spanfilter {
  height: 21px;
  /* font-family: MuseoSans; */
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #908e8e;
}

.Sessions-type {
  height: 24px;
  width: 100%;
  margin: 0 157px 16px 2px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33333a;
}

.ant-btn:hover,
.ant-btn:focus {
  /* color: #fff !important; */
  /* background: #fff !important; */
  border-color: #fff !important;
}

.ant-checkbox-inner {
  min-width: 20px !important;
  height: 20px !important;
  margin: 1px 10px 0 0 !important;
  border-radius: 4px !important;
  /* background-color: #efeff0 !important; */
}

.titleinput {
  height: 21px;
  margin: 0 0 0 0px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.2;
  letter-spacing: normal;
  color: #908e8e;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.titlefilter {
  height: 21px;
  float: right;
  /* margin: 0 0 0 79px; */
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.2;
  letter-spacing: normal;
  text-align: left;
  color: #908e8e;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f2871e !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f2871e !important;
  border-color: #f2871e !important;
}

.ant-checkbox-checked:hover .ant-checkbox-inner:hover {
  background-color: #f2871e !important;
  border-color: #f2871e !important;
}

.pagination {
  width: 100% !important;
  height: 40px !important;
  margin: 10px 0px 0px 0px !important;
  padding: 0 1px 0 0 !important;
}

.page-item {
  height: 40px !important;
  /* margin: 0 19px 0 0 !important; */
  padding: 10px 16px 9px !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
}

.page-item-active {
  background-color: #f2871e !important;
  color: white;
}

.pag {
  /* margin: 0 0 0 44px; */
  padding: 10px 10px 9px 10px;
  border-radius: 10px;
  border: solid 1px #ede8e9;
  background-color: #ffffff;
}



.Lable {
  font-weight: 600;
  line-height: 1.56;
  text-align: center;
  color: #ffffff;
}

.buttonbg:hover {
  background-color: #ff9900;
}

.buttonPlansbg {
  /*width: fit-content;*/
  /*min-width: 110px;*/
  /*height: fit-content;*/
  width: 70%;
  position: relative;
  top: 30%;
  padding: 8px 10px;
  border-radius: 12px;
  background-color: #f2871e;
}
.buttonbg {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 12px;
  background-color: #f2871e;
}

.Interactive-Virtual {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 1rem;
  margin: 0 0 0 0.438rem;
  font-size: 0.75rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #151517;
}

@media only screen and (min-width: 1300px) {
  .Background {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 3rem;
    padding: 0.938rem 0.83rem 1rem 0.857rem;
    border-radius: 16.2px;
    box-shadow: 0 3px 45px 0 rgba(103, 103, 103, 0.02);
    border: solid 1.3px #e8e8e8;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 1300px) {
  .Background {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 3rem;
    padding: 0.938rem 0.83rem 1rem 0.857rem;
    border-radius: 16.2px;
    box-shadow: 0 3px 45px 0 rgba(103, 103, 103, 0.02);
    border: solid 1.3px #e8e8e8;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-top: 20px;
  }
}

.circle {
  width: 0.857rem;
  height: 0.857rem;
  border-radius: 5.5px;
  box-shadow: 0 0 15px 0 rgba(103, 103, 103, 0.05);
  background-color: #ff8100;
}

.circle1 {
  width: 0.857rem;
  height: 0.857rem;
  border-radius: 5.5px;
  box-shadow: 0 0 15px 0 rgba(103, 103, 103, 0.05);
  background-color: #36b37e;
}

.imgsec1 {
  max-width: inherit;
  display: block;
  margin: auto;
  object-fit: cover;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ff8100 !important;
}
.dropdown-item {
  text-align: left;
}



.imgsec2 {
  width: 15.938rem;
  height: 15.938rem;
  display: block;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
  max-width: 100%;
}

.imgsec3 {
  width: 4rem;
  height: 4rem;
  border-radius: 12px;
  object-fit: cover;
}

.Lable3 {
  width: 4.958rem;
  height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  color: #36b37e !important;
}

.buttonbgFill {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 30.438rem 1rem 31.563rem;
  padding: 0.75rem 1.479rem 0.5rem 1.438rem;
  border-radius: 2500px;
  background-color: #e5f3f2;
  display: block;
  margin: auto;
}

@media only screen and (min-width: 1300px) {
  .cardfeat {
    padding-inline: 80px !important;
  }
}



.footerbg {
  background-color: #2b2e33;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.h1footer {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}

.hfooter {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
}

.divfooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icotext {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.icotext1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.spicon {
  padding-inline: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.footimg {
  margin-inline: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.txtfooter:hover {
  color: #f2871e !important;
}

.spicon :hover {
  color: #f2871e !important;
}

.spann {
  font-size: 14px;
  font-weight: 600;
  color: #908e8e;
}

.srchhead {
  font-size: 34px;
  font-weight: bold;
}



@media (max-width: 992px) {
  .allfilter {
    /* width: 70% !important; */
    display: block;
    margin: auto;
  }

  .divfooter {
    display: block !important;
  }

  .mobfoottxt {
    text-align: center;
    margin-bottom: 30px;
  }
}

.mobcard {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 12px;
  /* width: 100% !important; */
  display: block;
  /* margin: auto !important; */
}

@media (max-width: 992px) {
  .courseimg {
    display: block;
    margin: auto;
    margin-bottom: 5px;
    /* padding-top: 10px; */
    width: 100% !important;
    object-fit: fill;
  }
  .spicialimg {
    width: 100% !important;
    height: 100px !important;
  }
   .courseprice {
    margin-top: 0px !important;
  }
  .namecourse{
    margin-bottom: 0px !important;
  }
/*
  .coursecontent {
    text-align: center !important;
  } */

  .firstimg {
    width: 100%;
    border-radius: 12px;
    height: 250px !important;
    object-fit: fill;
  }
  .cardpad {
    padding: 10px 0px !important;
  }
}

/* @media (min-width: 1400px) {
        .coursecontent{
            padding-left: 35px !important;
        }
        } */
@media (min-width: 990px) and (max-width: 1400px) {
  .coursecontent {
    padding-left: 50px !important;
  }

  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 80% !important;
    min-width: 0;
  }

  .outlineborder1 {
    width: 20% !important;
  }
  .navcont {
    padding-inline: 20px !important;
  }
}

@media (min-width: 990px) {
  .butspan {
    text-align: right;
  }
  .butspan1 {
    text-align: right;
  }


}

@media (min-width: 768px) {
  .ulmob ul {
    width: -webkit-max-content;
    width: max-content;
    padding: 0px !important;
  }

  .ulmob {
    padding: 0px !important;
  }

}

@media (max-width: 768px) {
  .ulmob ul {
    width: auto;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .navcont {
    padding: 0px !important;
  }
}

.courseimg {
  width: 9.25rem;
  height: 9.25rem;
  border-radius: 15px;
  object-fit: fill;
}

.cardpad {
  padding: 20px 10px;
}

.cont {
  margin-top: 94px;
}

.cont2 {
  margin-top: 150px;
}

.cont3 {
  margin-top: 120px;
}

@media (max-width: 600px) {
  .mobpage {
    width: 90% !important;
    display: block;
    margin: auto;
    padding-left: 12px !important;
  }
}

.Mask {
  /* height: 15.5rem; */
  /* margin: 3.25rem 9.438rem 1.5rem 9.375rem; */
  /* padding: 0 0 0 3.25rem; */
  border-radius: 15px;
  background-color: #eff4f3;
}

.titlename {
  height: 2.25rem;
  /* margin: 3.875rem 0.688rem 0 1.688rem; */
  /* font-family: MuseoSans; */
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33333a;
}

.textname {
  /* height: 1.313rem !important; */
  font-size: 0.875rem !important;
  /* margin: 0.375rem 8.125rem 1.688rem 1.688rem; */

  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: normal !important;
  color: #908e8e !important;
}

.spicon1 {
  height: 2.875rem;
  margin: 0 0.375rem 0 0;
  padding: 0.838rem 0.938rem;
  border-radius: 15px;
  border: solid 1px #eceef5;
  background-color: #ffffff;
}

.iconsingle {
  color: #908e8e;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ee851d !important;
  font-weight: 700 !important;
  border-bottom-color: #eb8919 !important;
}

.ant-tabs-tab:hover {
  color: #ee851d !important;
}

.ant-tabs-nav-wrap {
  margin-top: 30px !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  margin-top: 20px;
}

.ant-tabs-tab {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  color: #8d8f99 !important;
}

.ant-tabs-ink-bar {
  background: #eb8919 !important;
}

.firstimg {
  width: 100%;
  border-radius: 12px;
  height: 600px;
  object-fit: fill;
}

.aboutinfo {
  /* margin: 2rem 10rem 1.5rem 9.375rem; */
  padding: 1.5rem 1.8rem 2.25rem !important;
  margin: 12px;

  border-radius: 12px;
  border: solid 1px #ede8e9;
  background-color: #ffffff;
}

.head {
  /* height: 1.688rem; */
  /* margin: 0 57.875rem 1.5rem 0; */

  font-size: 1.125rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33333a;
}

.headpar {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #908e8e;
}

.rbc-btn-group {
  /* display: none !important */
  background-color: #efeff0 !important;
  margin-bottom: 10px;
}

.rbc-toolbar-label {
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33333a;
}

.rbc-active {
  background-color: #eb8919 !important;
  color: #ffffff !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #eb8919 !important;
}

.rbc-today {
  background-color: rgb(204, 137, 60, 0.1) !important;
}

.ant-tabs-content-holder {
  margin-top: 30px !important;
}

.pseesions {
  font-size: 1.125rem;
  margin: 15px 0px;

  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #25252b;
  float: left;
}

.pseesions1 {
  margin: 15px 0px;
  float: right;
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #f2871e;
  cursor: pointer !important;
}

.firstimg {
  width: 100%;
  object-fit: fill;
}

#ff {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#fffweb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 767px) {
  #fffmob {
    display: none;
  }
}

@media (max-width: 767px) {
  #fffweb {
    display: none;
  }

  #fl2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  #fl12 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
  }
}

#fl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#fl1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonbg1:hover {
  background-color: #efeff9;
}

.buttonbg1 {
  padding: 0.875rem;
  border-radius: 12px;
  background-color: #efeff0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: block;
}

.buttonbg2:hover {
  background-color: #ff9900;
}

.buttonbg2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem 1.563rem;
  border-radius: 15.7px;
  background-color: #f2871e;
  display: block;
  border: 0px !important;
}

.button-disabled {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem 1.563rem;
  border-radius: 15.7px;
  background-color: #a2a2a2;
  display: block;
  border: 0px !important;
}

.Lable1 {
  height: 1.75rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.56;
  text-align: center;
  color: #ffffff;
}

.Lable1:hover {
  color: #fff4f4;
}

.text111 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #908e8e;
}

.head111 {
  font-size: 1.375rem;
  font-weight: bold;
}

.goals {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.circle2 {
  margin-top: 5px;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5.5px;
  box-shadow: 0 0 15px 0 rgba(103, 103, 103, 0.05);
  background-color: #f2871e;
  margin-inline: 10px;
}

.pseesions1:hover {
  color: #f2871e !important;
}

.namecourse {
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #33333a;
}

.namecourse:hover {
  color: #33333a !important;
}

.namecourse1 {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #908e8e;
}

.namecourse2 {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #908e8e;
}

.bordercard {
  border: solid 1px #ede8e9;
  margin: 0px 20px;
}

.namecourse3 {
  font-size: 0.875rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #33333a;
}

.namecourse3:hover {
  color: #33333a !important;
}

.namecourse4 {
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #33333a;
}

/* @media (max-width: 1003px) {

        .titlename {
            font-size: 1.3rem;
        }
    } */

@media (min-width: 992px) and (max-width: 1200px) {
  .titlename {
    font-size: 1.3rem;
  }
}

@media (max-width: 992px) {
  .mobcent {
    text-align: center !important;
  }

  .mobmargtop {
    margin-top: 30px !important;
    text-align: center !important;
  }

  .namecourse4 {
    text-align: left !important;
  }

  .cont2 {
    margin-top: 0 !important;
  }

}

@media (max-width: 768px) {
  .rbc-toolbar {
    display: block !important;
    text-align: center !important;
  }

  .rbc-toolbar-label {
    display: block !important;
    padding: 10px !important;
  }
  .buttonPlansbg {
    /*width: fit-content;*/
    /*min-width: 110px;*/
    /*height: fit-content;*/
    position: static;
    width: 70%;
    padding: 8px 10px;
    border-radius: 12px;
    background-color: #f2871e;
  }
}

.titlepop {
  font-size: 1.125rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center !important;
  color: #33333a !important;
  width: 100%;
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
  height: 43.5rem;
  margin: 0 0 0 0.063rem;
  /* padding: 5.875rem 1.563rem 5.75rem 2rem; */
  border-radius: 12px;
  box-shadow: 0 -6px 20px 0 rgb(153 155 168 / 15%);
  background-color: #ffffff;
}

.btn-close {
  /* font-size: 1.375rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center; */
  color: #33333a !important;
  font-weight: bold !important;
  font-size: 14px !important;
  opacity: 1 !important;
}

.btn-close:hover {
  color: #33333a !important;
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem #ffffff !important;
}

.modal-header {
  border-bottom: solid 1px #eceef5 !important;
  padding: 1.5rem 1rem !important;
}

.modal-footer {
  /* padding: 0.813rem 7.875rem 0.688rem 8.063rem !important; */
  border-radius: 15px !important;
  background-color: #efeff0 !important;
  height: 4.25rem !important;
}

.textfooter {
  /* height: 1.5rem; */
  /* margin: 0.563rem 0.375rem 0.688rem 0; */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center !important;
  color: #908e8e;
  width: 100%;
}

.signinfoot {
  font-size: 0.875rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #908e8e;
  margin-left: 7px;
}

.signinfoot:hover {
  color: #908e8e;
}

.filecard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* max-height: 4.875rem; */
  padding: 1.063rem 1rem 0 1.563rem;
}

@media (max-width: 768px) {
  .filecard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* max-height: 4.875rem; */
    padding: 0px;
  }
}

.filecard1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5.5rem;
  padding: 1.25rem 0.775rem 1.25rem 0.775rem;
  border-radius: 12px;
  border: solid 1px #ede8e9;
  background-color: #ffffff;
  background-color: aliceblue;
}

.file11 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.file22 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.file1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 70%;
}

.file2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 30%;
}

.filename {
  font-size: 0.875rem;
  font-weight: bold;
}

.filetxt {
  font-size: 0.875rem;
  font-weight: 600;
  color: #908e8e;
}

/* .filetxt1 {
  font-size: 10px;
  font-weight: 500;
  color: #f2871e;
} */
.instimg {
  margin-right: 20px;
}

.commimg {
  border-radius: 50%;
  object-fit: cover;
}



.input-group {
  width: 100% !important;
  align-items: center !important;
}

.form-control {
  border-radius: 12px !important;
  border: solid 1px #ede8e9 !important;
  background-color: #ffffff !important;
  height: 3rem !important;
  margin: 0 0 0 1.438rem !important;
  padding: 0.875rem 1rem 0.875rem 0.625rem !important;
}

#button-addon1 {
  border-radius: 12px;
  background-color: #f2871e;
  border-color: #f2871e !important;
  color: white !important;
}

.plus {
  font-size: 20px;
  font-weight: bold;
}

.Lable2 {
  width: 4.875rem;
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 1.56;
  text-align: center;
  color: #f2871e;
}

.Lable2:hover {
  color: #eec12e;
}

.group {
  position: relative;
  height: 4.5rem;
  margin: 0 0 1rem;
  padding: 0.813rem 1rem 1rem 1.25rem;
  border-radius: 15px;
  border: solid 1px #ede8e9;
  background-color: #ffffff;
}

.inputMaterial {
  /* font-size:18px; */
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33333a;
  /* border-bottom:1px solid #757575; */
}

.inputMaterial:focus {
  outline: none;
}

.label {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #908e8e;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
/* .inputMaterial:focus ~ label,
.inputMaterial:valid ~ label {
  top: 5px;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #908e8e;
} */

.btnformsign {
  height: 3.375rem !important;
  margin: 1rem 0 0 !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #ffffff !important;
  /* padding: 1.063rem 8.9rem 1rem 8.875rem !important; */
  border-radius: 12px !important;
  background-color: #f2871e !important;
  border-color: #f2871e !important;
}

.btnformsign:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.1px #f2871e !important;
}

.activecolorspan:focus {
  border-color: #feedde !important;
}

.rbc-addons-dnd,
.rbc-calendar {
  margin-bottom: 30px;
}

.cardhead {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.carddd {
  padding: 1.438rem 1.5rem 2rem;
  border-radius: 10px;
  border: solid 1px #ede8e9;
  background-color: #ffffff;
}

.disc {
  font-weight: 600;
  color: #908e8e;
  font-size: 0.875rem;
  margin-bottom: 0px;
}

.disc1 {
  color: #908e8e;
  font-size: 12px;
  font-weight: 700;
  margin-top: 20px;
}

@media (max-width: 992px) {
  .langchane {
    min-width: 100px !important;
    text-align: center !important;
    margin-bottom: 20px !important;
  }
  .picnot {
    width: 100%;
    max-height: 500px;
  }
  .disc1{
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
}

@media (min-width: 991px) {
  .langchane {
    min-width: 100px !important;
    text-align: center !important;
    /* margin-bottom: 20px!important; */
  }
  .picnot {
    width: 500px;
    height: 400px;
  }
}



.bg-btn2 {
  margin: 15px 24px 5px 10px;
  text-align: center;
  padding: 0.625rem 1.938rem 0.563rem;
  border-radius: 12px;
  background-color: #f2871e;
}

@media (min-width: 992px) and (max-width: 1400px) {
  .customclass {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .nav-item {
    padding-inline: 3px !important;
  }

  .bg {
    margin: 5px 10px 5px 10px !important;
  }

  .bg-btn2 {
    margin: 15px 10px 5px 10px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .nav-item {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .customclass {
  margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.techerimg {
  width: 150px !important;
  height: 150px !important;
  border-radius: 12px;
  object-fit: cover;
}

.techerimg1 {
  border-radius: 12px;
  object-fit: cover;
}

.spicialimg {
  width: 100% !important;
  height: 50px ;
  border-radius: 12px;
  object-fit: fill;
}

.nonee {
  display: none;
}

.upcomingimg {
  width: 100% !important;
  height: 100px !important;
  border-radius: 12px;
  object-fit: fill;
}

.searchpic {
  position: absolute !important;
  right: 0 !important;
}

.searchdiv {
  width: 100% !important;
}

.outlineborder {
  height: 2.5rem !important;
  margin: 0 !important;
  width: 73% !important;
}

.outlineborder:focus {
  box-shadow: 0 0 0 0.25rem rgb(238 133 29 / 25%) !important;
}

.outlineborder1:focus {
  box-shadow: 0 0 0 0.25rem rgb(238 133 29 / 25%) !important;
}

.paddingdiv {
  padding-left: 40px !important;
}

.pic {
  width: 40px;
  height: 40px;
}

@media (min-width: 980px) {
  .searchhome {
    width: 100% !important;

    /* height: 44px !important; */
  }
}

@media (max-width: 979px) {
  .searchhome {
    width: 100% !important;
  }


}
.teachimgbg {
  width: 100% !important;
  margin-top: 5%;
  height: 90% !important;
}

.navbar-light .navbar-toggler {
  color: #f2871e !important;
  border: 0px !important;
}

#rc-tabs-1-panel-2 {
  min-height: 700px !important;
}
.rbc-calendar {
  min-height: 700px !important;
}
.rbc-month-view {
  min-height: 500px !important;
}

.rbc-time-view {
  min-height: 500px !important;
}

.rbc-agenda-view {
  min-height: 500px !important;
}

.err {
  color: #f2871e !important;
  font-size: 12px !important;
}
.rbc-event-content{
  text-align: center !important;
}
.rbc-event-label{
  text-align: center !important;
}
/*
#special {
  position: relative;
  padding-top: 20px;
  display: inline-block;
}
.notify-badge {
  position: absolute;
  left: -20px;
  top: -15px;
  background: #f2871e;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
  padding: 5px 10px;
  font-size: 15px;
} */
.ant-ribbon {
  background-color: #f2871e !important;
}
.ant-ribbon.ant-ribbon-placement-end {
  left: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: auto !important;
  right: initial !important;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  left: 0 !important;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-end.badgg {
  right: auto !important;
  right: initial !important;
  left: -8px !important;
  top: -7px !important;
}

/*scroll bar style 1*/
#scrollbar-1::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
#scrollbar-1::-webkit-scrollbar {
  width: 12px;
  background-color: rgb(255, 255, 255);
}
#scrollbar-1::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #f2871e;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#scrollbar-2::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
#scrollbar-2::-webkit-scrollbar {
  width: 12px;
  background-color: rgb(255, 255, 255);
}
#scrollbar-2::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #f2871e;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#scrollbar-3::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
#scrollbar-3::-webkit-scrollbar {
  width: 12px;
  background-color: rgb(255, 255, 255);
}
#scrollbar-3::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #f2871e;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#scrollbar-4::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
#scrollbar-4::-webkit-scrollbar {
  width: 12px;
  background-color: rgb(255, 255, 255);
}
#scrollbar-4::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #f2871e;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
/* @media (min-width: 767px) {
  #cardmob {
    display: none !important;
  }
}
@media (max-width: 767px) {
  #cardweb {
    display: none !important;
  }
} */

@media (min-width: 990px) {
  .butspan2 {
    text-align: right;
}
.butspan3 {
  text-align: right;
}
}
.Lable:hover{
  color: white;
}

@media (max-width:979px) {
.cardnew {
  text-align: center;
  margin: 0 0 16px 0;
  padding: 10px;
  border-radius: 11.4px !important;
  border: solid 0.5px #ede8e9;
  background-color: #ffffff;
  max-width: 65%;
  margin: auto !important;
  margin-bottom: 10px !important;
}
.paddingdiv{
  padding-left: 10px !important;
}
.pagination{
  padding-left: 20px !important;
}
}

.noitems{
  color: #f2871e ;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

/*pricing component*/

[dir=ltr] .pricing-card .annual-plan .plan-price sup {
  left: 0.2rem !important;
}

.pricing-card .annual-plan .plan-price sup {
  top: -1.5rem !important;
}

[dir=ltr] .mr-25, [dir=ltr] .mx-25 {
  margin-right: 0.25rem !important;
}

.font-medium-1 {
  font-size: 1.1rem !important;
}

.font2 {
  font-size: 0.8rem !important;
}

.pricing-card .annual-plan .plan-price span {
  font-size: 3.5rem !important;
  line-height: 0.8 !important;
}

.font-weight-bolder {
  font-weight: 600 !important;
  font-size: 55px !important;
}

.font-weight-bolder2 {
  font-weight: 600 !important;
  font-size: 18px !important;
}

[dir] .pricing-card .annual-plan {
  margin-bottom: 1.7rem !important;
}

sup {
  top: -1.5em !important;
}


[dir] .list-group.list-group-circle {
  border: none;
}

.pricing-card .list-group-circle {
  font-weight: 500;
  color: #5e5873;
}

.list-group {
  display: flex;
  flex-direction: column;
}

[dir] .list-group {
  margin-bottom: 0;
  border-radius: 0.358rem;
}

[dir] .list-group {
  padding-left: 0;
}

[dir] .list-group.list-group-circle .list-group-item {
  padding-left: 1.5rem;
}

[dir] .list-group.list-group-circle .list-group-item {
  padding-left: 1.5rem;
}

.list-group.list-group-circle .list-group-item {
  position: relative;
}

[dir] .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

[dir] .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(34, 41, 47, 0.125);
  color: #767272 !important;
}

.list-group-item {
  position: relative;
  display: block;
  border: none !important;
}

[dir=ltr] .list-group.list-group-circle .list-group-item:after {
  left: 0 !important;
}

[dir=rtr] .list-group.list-group-circle .list-group-item:after {
  right: 0 !important;
}

[dir] .list-group.list-group-circle .list-group-item:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
}

.list-group.list-group-circle .list-group-item:after {
  content: ' ';
  color: #6e6b7b;
  position: absolute;
  height: 10px;
  width: 10px;
  top: 1.15rem;
}

[dir] .list-group .list-group-item:hover {
  background: none !important;
}

.pro {
  border: solid 2px #f2871e !important;
}

.freetxt {
  font-size: 33px;
  font-weight: 700;
  color: #f2871e;
  padding: 10px;
  margin-bottom: 20px;

}

.pricingimg {
  max-width: 80%;
  max-height: 150px;
}

.modal .modal-header .close span {
  font-weight: 400;
  font-size: 2rem;
  color: #f2871e !important;
}

.spancardV {
  color: #f2871e;
  font-weight: 700;
}
.cardPrice:hover {
  box-shadow: 0px 10px 20px 10px rgba(255, 166, 108, 0.9) !important;
  margin-top: 20px !important;
  transition: all 0.3s ease-in-out;
}
/* features page */

.vlccard{
  background-color: antiquewhite;
  border-radius: 12px;
}
#Features{
  padding-top: 100px;
}
.spancard{
  font-size: 28px;
  font-weight: bold;
  color: #ce8900;
}
.spancardsmall{
  font-size: 24px;
  font-weight: bold;
  color: #ce8900;
}
.SmallCard{
  padding: 20px;
  border-radius: 6px;
  border: solid 0.5px #eae5e5;
  background-color: #ffffff;
}
@media (max-width:979px) {
  .imgOfcard {
    text-align: center;
  }
  .textOfcard{
    text-align: center;
  }
  .vlccard{
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
.spanspin {
  color: #f2871e;
}
.border-radius{
  border-radius: 12px!important;
}
.notfoundbtn {
  font-size: 18px !important;
  padding: 10px 15px !important;
  background-color: #f2871e !important;
  border-color:  #f2871e !important;
}

[dir] .react-confirm-alert-overlay {
  background: rgba(255, 255, 255, 0.1) !important;
}



.react-confirm-alert-button-group {

  justify-content: center !important;
}

.alertH1 {
  font-size: 20px !important;
  text-align: center !important;
}

.delete {
  background-color: red !important;
}

.no {
  background-color: #ff8510 !important;
}

.alertSize {
  font-size: 1rem !important;
}


